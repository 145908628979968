var menu_items = [
  {
    icon: "mdi-view-dashboard",
    title: "Dashboard",
    to: "/",
    module: "Dashboard"
  },
  {
    icon: "mdi-shopping-outline",
    title: "Orders",
    to: "/orders",
    module: "Orders"
  },
  {
    icon: "mdi-cart-plus",
    title: "Purchases",
    to: "/purchases",
    module: "Purchases"
  },
  {
    icon: "mdi-package-variant",
    title: "Products Manager",
    module: "Default",
    group: "",
    children: [
      {
        icon: "mdi-shape",
        title: "Main Categories",
        to: "categories",
        module: "Categories"
      },
      {
        icon: "mdi-shape-outline",
        title: "Sub Categories",
        to: "sub-categories",
        module: "SubCategories"
      },
      {
        icon: "mdi-package-variant",
        title: "Product Details",
        to: "products",
        module: "Products"
      },
      {
        icon: "mdi-glass-fragile",
        title: "Damaged Products",
        to: "damaged-products",
        module: "Products"
      },
      {
        icon: "mdi-point-of-sale",
        title: "Sales Orders",
        to: "sales_channels",
        module: "Products"
      }
    ]
  },
  {
    icon: "mdi-account-group",
    title: "Support",
    module: "Default",
    group: "",
    children: [
      {
        icon: "mdi-ticket",
        title: "Support Tickets",
        to: "tickets",
        module: "Tickets"
      },
      {
        icon: "mdi-package-down",
        title: "Requested Products",
        to: "product-request-customer",
        module: "ProductRequest"
      }
    ]
  },
  {
    icon: "mdi-cards-outline",
    title: "Banners",
    to: "/banner",
    module: "Banner"
  },
  // {
  //   icon: "mdi-account-details",
  //   title: "User Info",
  //   to: "/user-info",
  //   module: "UserInfo",
  // },
  // {
  //   icon: "mdi-sale",
  //   title: "Offers",
  //   to: "/offers",
  //   module: "Offers",
  // },

  // {
  //   icon: "mdi-close-octagon",
  //   title: "Driver Cancel Order",
  //   to: "/driver-cancel-order",
  //   module: "DriverCancelOrder"
  // },

  // {
  //   icon: "mdi-currency-usd",
  //   title: "Driver Earning & Payout",
  //   to: "/driver-payout",
  //   module: "DriverPayout",
  // },

  {
    icon: "mdi-chef-hat",
    title: "Recipes",
    to: "/recipes",
    module: "Recipes"
  },
  {
    icon: "mdi-sale",
    title: "Promo-Codes",
    to: "/promocode",
    module: "Promocode"
  },
  {
    icon: "mdi-account-check-outline",
    title: "Roles",
    to: "/roles",
    module: "Roles"
  },
  {
    icon: "mdi-account-group",
    title: "Users",
    module: "Default",
    group: "/users",
    children: [
      {
        icon: "mdi-account-multiple",
        title: "Customers",
        to: "customers",
        module: "Customers"
      },
      {
        icon: "mdi-account-supervisor-circle",
        title: "Drivers",
        to: "drivers",
        module: "Drivers"
      },
      {
        icon: "mdi-account-tie",
        title: "Admin Staff",
        to: "staff",
        module: "Admin Staff"
      }
    ]
  },
  {
    icon: "mdi-chart-line",
    title: "Reports",
    to: "/reports",
    module: "Reports"
  },
  {
    icon: "mdi-cog-outline",
    title: "Settings",
    module: "Default",
    group: "",
    children: [
      {
        icon: "mdi-cog-outline",
        title: "General Settings",
        to: "general-settings",
        module: "Settings"
      },
      {
        icon: "mdi-email-edit-outline",
        title: "Email Templates",
        to: "register-customer-email",
        module: "Settings"
      },
      {
        icon: "mdi-store-plus",
        title: "Warehouse",
        to: "stores",
        module: "Stores"
      },
      {
        icon: "mdi-truck",
        title: "Vans",
        to: "vans",
        module: "Vans"
      },
      {
        icon: "mdi-bell-ring",
        title: "Push Notification",
        to: "push-notification",
        module: "PushNotification"
      }
      // {
      //   icon: "mdi-map-marker",
      //   title: "Glugglers Addresses",
      //   to: "predefined-address",
      //   module: "PredefinedAddress"
      // }
    ]
  }
];
module.exports = menu_items;
